<template>
    <section class="taxes">
        <!-- header -->
        <div class="page-header">
            <h3 class="page-title" data-aos="fade-left"
            data-aos-anchor="#example-anchor"
            data-aos-offset="500"
            data-aos-duration="2000" >
                <span class="page-title-icon bg-gradient-primary text-white mr-2">
                <i class=" mdi mdi-currency-eur"></i>
                </span> Taxes
            </h3>
        </div>

        <!-- content -->
        <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
            <div class="card-body">
                <h4 class="card-title">Taux de taxe</h4>
                <p class="card-description">
                <!-- Bootstrap contextual state applied to the row and column -->
                </p>
                <b-table hover responsive :items="taxes" :fields="fields" so>
                    <template #cell(name)="data">
                        {{ data.item.name }}
                    </template>
                    <template #cell(seller)="data">
                        {{ data.item.seller_country_alpha2 }}
                    </template>
                    <template #cell(buyer)="data">
                        {{ data.item.buyer_country_alpha2 }}
                    </template>
                    <template #cell(rate)="data">
                        {{ (data.item.rate)*100 }}%
                    </template>
                    <template #cell(description)="data">
                        {{ data.item.description }}
                    </template>
                </b-table>
            </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
export default {
    name:'taxes',
    data(){
        return{
            taxes:[],
            fields: [
                {
                    key: 'name',
                    label: 'type',
                    sortable: true
                },
                {
                    key: 'seller',
                    label: 'Vendeur',
                    sortable: false
                },
                {
                    key: 'buyer',
                    label: 'Acheteur',
                    sortable: false
                },
                {
                    key: 'rate',
                    label: 'taux',
                    sortable: true
                },
                {
                    key: 'description',
                    label: 'Description',
                    sortable: true
                }
            ]
        }
    },
    methods:{
        listTaxes(){
            axios.get('taxes')
            .then(resGetTaxes => {
                this.taxes = resGetTaxes.data.result
            })
            .catch(errGetTaxes => {
                console.error(errGetTaxes)
            })
        }
    },
    mounted(){
        this.listTaxes()
    }
}
</script>